<template>
   <div class="page">
    <all-order-type :propStatus="1"></all-order-type>
  </div>
</template>

<script>
import AllOrderType from '../orderList/allOrderType'
  export default {
    name: 'OrderPending',
    components: {
      AllOrderType
    },
    data() {
      return {
      };
    },
    methods: {
    },
  }
</script>

<style scoped>
</style>